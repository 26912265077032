import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ReactSVG } from "react-svg";
import Countdown from "react-countdown";
import Renderer from "../components/countdown/Renderer";
import SubscribeEmail from "../components/newsletter/SubscribeEmail";

const ComingSoon18 = () => {
  return (
    <Fragment>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
        <style>
          {`
            .cs-18-countdown .single-countdown__text{
              background-image: url(${
                process.env.PUBLIC_URL + "/assets/img/icons/clock.svg"
              })
            }
          `}
        </style>
      </Helmet>
      <div
        className="cs-18-page-wrapper h-100 bg-img d-flex flex-column justify-content-between"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL +
            "/assets/img/backgrounds/coming-soon-18.jpg"
          })`
        }}
      >
        {/*====================  header ====================*/}
        <header className="cs-18-header space-pt--30 space-pb--30">
          <div className="container">
            <div className="row">
              <div className="col-4">
                {/* logo */}
                <div className="cs-18-logo">
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/logo.png"
                      }
                      className="img-fluid"
                      alt=""
                      width={200}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/*====================  End of header  ====================*/}
        {/*====================  content ====================*/}
        <div className="cs-18-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <h3 className="cs-18-content__subtitle space-mb--10">
                  COMING SOON
                </h3>
                <h2 className="cs-18-content__title">
                  We are building <br /> something awesome!!
                </h2>
                {/* countdown */}
                <div className="cs-18-countdown space-mt--50">
                  <Countdown
                    date={new Date("January 15, 2023 12:00:00")}
                    renderer={Renderer}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of content  ====================*/}
        {/*====================  footer ====================*/}
        <footer className="cs-18-footer space-pt--25 space-pb--25">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                {/* social icons */}
              </div>
              <div className="col-12 col-md-6">
                {/* copyright */}
                <div className="cs-18-copyright text-right">
                  &copy; {new Date().getFullYear() + " "}
                  <a
                    href="https://hasthemes.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Respectly
                  </a>
                  , all rights reserved
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*====================  End of footer  ====================*/}
      </div>
    </Fragment>
  );
};

export default ComingSoon18;
