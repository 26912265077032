import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./assets/scss/style.scss";

import ComingSoon01 from "./pages/comingSoon01";
import ComingSoon02 from "./pages/comingSoon02";
import ComingSoon03 from "./pages/comingSoon03";
import ComingSoon04 from "./pages/comingSoon04";
import ComingSoon05 from "./pages/comingSoon05";
import ComingSoon06 from "./pages/comingSoon06";
import ComingSoon07 from "./pages/comingSoon07";
import ComingSoon08 from "./pages/comingSoon08";
import ComingSoon09 from "./pages/comingSoon09";
import ComingSoon10 from "./pages/comingSoon10";
import ComingSoon11 from "./pages/comingSoon11";
import ComingSoon12 from "./pages/comingSoon12";
import ComingSoon13 from "./pages/comingSoon13";
import ComingSoon14 from "./pages/comingSoon14";
import ComingSoon15 from "./pages/comingSoon15";
import ComingSoon16 from "./pages/comingSoon16";
import ComingSoon17 from "./pages/comingSoon17";
import ComingSoon18 from "./pages/comingSoon18";
import ComingSoon19 from "./pages/comingSoon19";
import ComingSoon20 from "./pages/comingSoon20";

import LandingPage from "./pages";
import LandingPageTf from "./pages/DemoTf";
import LandingPageCm from "./pages/DemoCm";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          component={ComingSoon18}
        />
      </Switch>
    </Router>
  );
}

export default App;
